<template>
  <v-text-field hide-details label="Freigabelink" readonly ref="textField" :value="url">
    <template v-slot:append-outer>
      <temeno-button
        action
        small
        v-if="url && !readonly"
        @click="remove"
      >
        <v-icon class="mr-1" small>$delete</v-icon>
        {{ $t("remove") }}
      </temeno-button>

      <temeno-button
        action
        small
        class="ml-1"
        style="min-width: 113px"
        v-if="url"
        @click="copy"
      >
        <v-icon class="mr-1" small>{{ copyButtonIcon }}</v-icon>
        {{ copyButtonText }}
      </temeno-button>

      <temeno-button
        action
        small
        v-if="!url && !readonly"
        @click="create"
      >
        <v-icon class="mr-1" small>$plus</v-icon>
        {{ $t("create") }}
      </temeno-button>
    </template>
  </v-text-field>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "ShareLinkInput",
  components: {
    TemenoButton: () => import("@/components/TemenoButton"),
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: String, // UUID
  },
  data() {
    return {
      copied: false,
    };
  },
  computed: {
    copyButtonColor() {
      return this.copied ? "success" : "grey darken-2";
    },
    copyButtonIcon() {
      return this.copied ? "$approve" : "$copy";
    },
    copyButtonText() {
      return this.copied ? this.$t("copied") : this.$t("copy");
    },
    url() {
      return this.value
        ? this.$store.getters["connection/serverBaseUrl"] + "/go/" + this.value
        : null;
    },
  },
  methods: {
    copy() {
      const input = this.$refs.textField.$el.querySelector("input");
      input.select();
      input.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.copied = true;
      setTimeout(this.reset, 1000);
    },
    create() {
      this.$emit("input", uuidv4());
    },
    remove() {
      this.$emit("input", null);
    },
    reset() {
      this.copied = false;
    },
  },
  i18n: {
    messages: {
      de: {
        create: "erzeugen",
        copy: "kopieren",
        copied: "kopiert",
        remove: "löschen",
      },
    },
  },
};
</script>

<style scoped>
.v-input {
  margin: 0;
}
.v-text-field >>> input {
  font-family: monospace;
  font-size: 12px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
</style>
